import * as React from 'react';
import './Footer.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

function Footer() {
  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        component="footer"
        sx={{
          background: '#2b2b40',
          borderTop: '0.5em solid #65cc87',
          mt: 8,
          py: [3, 6],
        }}
      >
        <Container maxWidth={'lg'}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={4} className="item contact-details">
              <a href="mailto:support@arcmutate.com">support@arcmutate.com</a>
            </Grid>
            <Grid item xs={12} md={2} className="item">
              <ul className="footer-links">
                <li><a href={process.env.REACT_APP_ARCMUTATE_SITE_URL + '/about'}>About</a></li>
                <li><a href={process.env.REACT_APP_ARCMUTATE_SITE_URL + '/privacy'}>Privacy</a></li>
                <li><a href="https://docs.arcmutate.com">Docs</a></li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6} className="item">
              <div className="address"> 
              </div>
            </Grid>
            {/*</Grid>*/}

            <Grid container item justifyContent="center" xs={12}>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </React.Fragment>
  )
}

export default Footer;
