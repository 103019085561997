import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Success from "./Success";
import Pricing from "./Pricing";
import Trial from "./Trial";
import React, {useState} from "react";
import Checkout from "./Checkout";
import Reload from "./Reload";
import { createTheme, ThemeProvider } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

const theme = createTheme({
  typography: {
    fontFamily: [
      'San Francisco',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
  },
});

function App() {
  const [packages, setPackages] = useState('');
  const [teamSize, setTeamSize] = useState(1);
  const [frequency, setFrequency] = useState('month');
  const [subscriptionType, setSubscriptionType] = useState('pro');

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Pricing teamSize={teamSize}
                                            setTeamSize={setTeamSize}
                                            frequency={frequency}
                                            setFrequency={setFrequency}
                                            setSubscriptionType={setSubscriptionType} />} />
          <Route path='/trial' element={<Trial teamSize={teamSize}
                                            frequency={frequency}
                                            subscriptionType={subscriptionType}
                                            packages={packages} setPackages={setPackages}
                                            />} />
          <Route path='/reload/:data' element={<Reload setPackages={setPackages}
                                                       setTeamSize={setTeamSize}
                                                       setFrequency={setFrequency}
                                                       setSubscriptionType={setSubscriptionType} />} />
          <Route path='success/:token' element={<Success />} />
          <Route path='checkout' element={<Checkout checkoutType = 'SALE'
                                                    subscriptionType={subscriptionType}
                                                    teamSize={teamSize}
                                                    frequency={frequency}
                                                    packages={packages} setPackages={setPackages}
                                                    showBackButton={true}
          />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
