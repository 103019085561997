import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import Checkout from "./Checkout";


function Trial({
  teamSize,
  frequency,
  subscriptionType,
  packages, 
  setPackages
}) {

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center" sx={{
          pt: 8,
          pb: 6
        }}>
          <h2 className="page-header">
            Free Evaluation Licence
          </h2>
          <p className="subtext text-center">
            If you'd like to try Arcmutate, we'd be happy to provide a <strong>free</strong> 30 day evalutation licence.</p>
          <p className="subtext text-center"> We just need to know the root package of your codebase. Enter it in the box below,
            and you'll be taken to stripe.com where you'll be asked to fill in some additional details. You will <strong>not</strong> be asked for a credit
            card number.
          </p>
      
          <Checkout 
              checkoutType = 'TRIAL'
              subscriptionType={subscriptionType}
              teamSize={teamSize}
              frequency={frequency}
              packages={packages} 
              setPackages={setPackages}/>


        <p  className="subtext text-center">Alternatively <a href="mailto:support@arcmutate.com">email us</a> to discuss your requirements.</p>
        </Grid>
      </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
}

export default Trial;
