import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import {Container} from "@mui/material";

const Success = () => {
    const { token } = useParams();

    const [licenceDomain, setLicenceDomain] = useState(null);

    const host = process.env.REACT_APP_SERVER_URL;

    React.useEffect(() => {
        fetch(
            host + '/config',
            {
                method: 'GET',
            }
        ).then(response => {
            response.json().then(json => {
                setLicenceDomain(json.licenceDomain);
            })
        }).catch((error) => {
            console.log(error);
        });
    }, [setLicenceDomain, host]);

    return (
        <Container component="main" maxWidth="md">
        <h1>Subscription created</h1>
            <h2>Licence</h2>
            <p>You will receive an e-mail shortly confirming your subscription.</p>

            {licenceDomain !== null && 
            <p>
            <a href={licenceDomain + "/" + token + "/arcmutate-licence.txt"}>Your licence file is available to download here</a>
            <p>The latest version of your licence will always be available at this URL.</p>
            </p>
            }

            <h2>Manage your subscription</h2>
            <p><a href="https://billing.stripe.com/p/login/fZe3fi9aQ0AO2UofYY">Login to our customer portal</a> with the email address used during payment to 
            make changes to your plan and access invoices and receipts.</p>

            <p>Happy mutating.</p>
        </Container>
    );
}
export default Success;
