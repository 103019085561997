import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";
import { TextField, Alert } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";


class Prices {
  constructor(base, kotlin, pro) {
    this.base = base;
    this.kotlin = kotlin;
    this.pro = pro;
  }
}

function Pricing({
  teamSize,
  setTeamSize,
  frequency,
  setFrequency,
  setSubscriptionType,
}) {
  const [showGrid, setShowGrid] = useState(true);

  const [prices, setPrices] = useState({});

  const [currentPrice, setCurrentPrice] = useState(new Prices(800, 900, 1200));

  const [messages, setMessages] = useState([]);

  const host = process.env.REACT_APP_SERVER_URL;

  const history = useNavigate();
  const gotoCheckout = (subType) => {
    setSubscriptionType(subType.toLowerCase());
    if (teamSize > 0) {
      history("/checkout");
    }
  };

  const fetchPrices = useCallback(() => {
    fetch(host + "/pricing", {
      method: "GET",
    })
      .then((response) => {
        response.json().then((json) => {
          setPrices(json);
        });
      })
      .catch((error) => {
        console.log(error);
        setShowGrid(false);
        setMessages([
          "Pricing is not available just now. Please try again later.",
        ]);
      });
  }, [host]);

  const calculatePrices = useCallback(
    (size) => {
      function calculateTotal(sub, size) {
        let allocated = 0;
        let total = 0;
        for (const band of sub.bands) {
          let inThisBand = Math.min(size - allocated, band.upTo - allocated);
          total = total + inThisBand * band.price;
          allocated = allocated + inThisBand;
        }
        return total;
      }

      if (prices.base) {
        if (frequency === "month") {
          setCurrentPrice(
            new Prices(
              calculateTotal(prices.monthlyBase, size),
              calculateTotal(prices.monthlyKotlin, size),
              calculateTotal(prices.monthlyPro, size)
            )
          );
        } else {
          setCurrentPrice(
            new Prices(
              calculateTotal(prices.base, size),
              calculateTotal(prices.kotlin, size),
              calculateTotal(prices.pro, size)
            )
          );
        }
      }
    },
    [
      prices.base,
      prices.kotlin,
      prices.pro,
      prices.monthlyBase,
      prices.monthlyKotlin,
      prices.monthlyPro,
      frequency,
    ]
  );

  function updateQuote(event) {
    if (event.target.value && event.target.value > 0) {
      setTeamSize(event.target.value);
      calculatePrices(parseInt(event.target.value));
    }
  }

  React.useEffect(() => {
    if (!prices.base) {
      fetchPrices();
    }
    calculatePrices(teamSize);
  }, [teamSize, calculatePrices, prices.base, fetchPrices]);

  const handleChange = (event) => {
    setFrequency(event.target.value);
  };

  const tiers = [
    {
      title: "Base",
      price: currentPrice.base,
      theme: "#19c6e5ff",
      description: [
        "JUnit 5 Acceleration",
        "Extended Mutation Operators",
        "Subsumption Analysis",
        "Mutant Exclusions",
      ],
      buttonText: "Subscribe",
      buttonVariant: "outlined",
    },
    {
      title: "Kotlin",
      price: currentPrice.kotlin,
      theme: "#65cca1ff",
      description: [
        "Kotlin Support",
        "JUnit 5 Acceleration",
        "Extended Mutation Operators",
        "Subsumption Analysis",
        "Mutant Exclusions",
      ],
      buttonText: "Subscribe",
      buttonVariant: "outlined",
    },
    {
      title: "Pro",
      price: currentPrice.pro,
      theme: "#65cc6eff",
      description: [
        "Spring Support",
        "Kotlin Support",
        "Git Integration",
        "GitHub Integration",
        "GitLab Integration",
        "BitBucket Integration",
        "Azure Integration",
        "and more...",
      ],
      buttonText: "Subscribe",
      buttonVariant: "outlined",
    },
  ];

  // conditionally show/dont show grid if there has been an error response from the backend
  const grid = showGrid ? (
    <Container maxWidth="md" component="main">
      <Grid container spacing={5} alignItems="flex-start">
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid height={"520px"} item key={tier.title} xs={12} sm={4} md={4}
                sx={{display: "flex", flexGrow: 1}}>
            <Card sx={{ display: "flex", flexDirection: "column", flexGrow: 1}}>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{ backgroundColor: tier.theme }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  {tier.price > 0 && (
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      ${tier.price / 100}
                    </Typography>
                  )}
                  <Typography variant="h6" color="text.secondary">
                    /{frequency}
                  </Typography>
                </Box>
                <ul>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions sx={{flexGrow: 1, alignItems: "flex-end"}}>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  onClick={(e) => gotoCheckout(tier.title)}
                  back={tier.theme}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  ) : (
    <Container />
  );

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center" sx={{
          pt: 8,
          pb: 6
        }}>
          <h2 className="page-header">
            Online Subscription Plans
          </h2>
          <p className="subtext text-center">
            Pick the plan that works for you based on the functionality you require and the size of
            your team. Or <a href="/trial">request</a> a <strong>free</strong> evaluation licence.
          </p>
        </Grid>
      </Container>
      <Container
        maxWidth="sm"
        component="main"
        sx={{
          pb: showGrid ? 6 : 0
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            component="p"
          >
            What size is your team?
          </Typography>

          <Typography
            align="center"
            color="text.secondary"
            component="p"
            marginBottom="12px"
          >
            Arcmutate is priced by the number of people with commit access to
            your repos.
          </Typography>

          <TextField
            InputProps={{
              inputProps: {
                min: 1,
                max: 100000,
                style: { textAlign: "center" },
              },
            }}
            onChange={updateQuote}
            type="number"
            id="teamSize"
            label="Team Size"
            defaultValue={teamSize}
          />

          <Typography
            marginTop="18pt"
            variant="h5"
            align="center"
            color="text.secondary"
            component="p"
          >
            Choose how to pay
          </Typography>

          <Typography align="center" color="text.secondary" component="p">
            Pay monthly and cancel at any time, or pay annually and get 2 months
            free
          </Typography>
          <Grid>
            <FormControl>
              <RadioGroup
                row
                name="radio-buttons-group"
                value={frequency}
                onChange={handleChange}
                sx={{
                  flexDirection: {
                    xxs: "column",
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }
                }}
              >
                <FormControlLabel
                  value="month"
                  control={<Radio />}
                  label="Monthly subscription"
                />
                <FormControlLabel
                  value="year"
                  control={<Radio />}
                  label="Annual subscription"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {messages.map(function (message, index) {
            return (
              <Alert
                style={{ width: "75%", padding: "20px", margin: "20px" }}
                severity="info"
                key={index}
              >
                {message}
              </Alert>
            );
          })}
        </Grid>
      </Container>
      {/* End hero unit */}
      {grid}
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center" sx={{ pt: 8 }}>
          <h2 className="page-header">
            Enterprise Licencing
          </h2>
          <p className="subtext">
            We offer seperately priced enterprise licencing for organisations requiring large number of licences, additional support, or that are unable to purchase online. Please contact us at <a
            href="mailto:support@arcmutate.com">support@arcmutate.com</a> for details.
          </p>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center" sx={{ pb: 8 }}>
          <h2 className="page-header">
            Pricing FAQ
          </h2>
          <Grid container component="dl" direction="row" alignItems="center" className="faq">
            <Grid item xs={12} md={6}>
              <dt>What is meant by a 'user'?</dt>
              <dd>A user is anyone with commit access to a repository you want to analyse</dd>
            </Grid>
            <Grid item xs={12} md={6}>
              <dt>How do I log in to the <a
                href="https://billing.stripe.com/p/login/fZe3fi9aQ0AO2UofYY">customer portal</a>
              </dt>
              <dd>Enter the e-mail address you used to subscribe and you'll be sent a one time pass
                code
              </dd>
            </Grid>
            <Grid item xs={12} md={6}>
              <dt>What if my teams grows?</dt>
              <dd>More users can be added to a subscription via our <a
                href="https://billing.stripe.com/p/login/fZe3fi9aQ0AO2UofYY">customer portal</a>
              </dd>
            </Grid>
            <Grid item xs={12} md={6}>
              <dt>Do you offer pricing for open source projects?</dt>
              <dd>Arc mutate is free for open source projects.</dd>
            </Grid>
            <Grid item xs={12} md={6}>
              <dt>How do I switch to a different subscription plan?</dt>
              <dd>You can upgrade your plan using our <a
                href="https://billing.stripe.com/p/login/fZe3fi9aQ0AO2UofYY">customer portal</a>
              </dd>
            </Grid>
            <Grid item xs={12} md={6}>
              <dt>Questions?</dt>
              <dd>Contact us for any further questions at <a
                href="mailto:support@arcmutate.com">support@arcmutate.com</a></dd>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Pricing;
