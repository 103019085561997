import * as React from 'react';
import logo from './images/logo.svg';
import { Container, Grid } from '@mui/material';
import './Header.css';

function Header() {
  return (
    <React.Fragment>
      <Container component="header" maxWidth={false}>
        <Container maxWidth={'lg'}>
          <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={10} sm={4}>
              <div className="company-name">
                <a href={process.env.REACT_APP_ARCMUTATE_SITE_URL}>
                  <img src={logo} alt="Logo" width="95" height="33" />
                </a>
                <a href={process.env.REACT_APP_ARCMUTATE_SITE_URL}>arcmutate</a>
              </div>
            </Grid>
            <Grid item xs={2} sm={8}>
              <nav>
                <div className="nav-toggle" id="open-nav">&#9776;</div>
                <a href={process.env.REACT_APP_ARCMUTATE_SITE_URL + '/mutation/'}>What is Mutation Testing?</a>
                <a href={process.env.REACT_APP_ARCMUTATE_SITE_URL + '/benefits/'}>Benefits</a>
                <a href="/trial" className="">Try for Free</a>
                <a href="/" className="">Pricing</a>
                <a href="https://docs.arcmutate.com" className="">Docs</a>
                <a href="https://blog.arcmutate.com">Blog</a>
                <a href="https://billing.stripe.com/p/login/fZe3fi9aQ0AO2UofYY">Login</a>
              </nav>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default Header;
