import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import {useState} from "react";
import {Alert} from '@mui/material/';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";


function Checkout({checkoutType, subscriptionType, teamSize, frequency, packages, setPackages, showBackButton}) {
    const [messages, setMessages] = useState([]);

    //  const host = 'https://y8sld0kixc.execute-api.eu-west-1.amazonaws.com/Prod'
    const host = process.env.REACT_APP_SERVER_URL;

    const history = useNavigate();

    function isValidChar(str) {
        return /^[a-z0-9.]+$/i.test(str);
    }

    if (!ValidatorForm.hasValidationRule('isValidPackage')) {
        ValidatorForm.addValidationRule('isValidPackage', (value) => {
            if (value.replaceAll('.', '').length < 5) {
                return false;
            }
            if (value.endsWith(".") || !isValidChar(value)) {
                return false;
            }
            if (value.includes("..")) {
                return false;
            }
            const parts = value.split('.').length - 1;
            if (parts < 1) {
                return false;
            }
            return true;
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!subscriptionType || !packages || teamSize === 0) {
            setMessages(['Hmm, we are missing some data. Head back to pricing and try again.'])
            return;
        }

        fetch(
            host + '/checkout',
            {
                method: 'POST',
                body: JSON.stringify({
                    checkoutType: checkoutType,
                    subscriptionType: subscriptionType,
                    frequency: frequency,
                    packages: packages,
                    teamSize: teamSize,
                }),
            }
        ).then(response => response.json().then(json => {
            if (json.error === null) {
                // ensure back works
                window.history.pushState({}, "", window.location);
                window.location.replace(json.url);
            }
        }))
            .catch((error) => {
                console.log(error);
                setMessages(['There was a problem generating a quote please try again later.'])
            });
    };


    const back = showBackButton ?
                    <Button
                        onClick={e => history('/')}
                        variant="outlined"
                        color="secondary"
                    >
                        Back to pricing
                    </Button>
     :
    <Container/>;

    
    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <ValidatorForm
                onSubmit={handleSubmit}
            >
            {/* Hero unit */}
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Stack direction="column" justifyContent="end" spacing={2}>
                <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                   What is the root package of your codebases?
                </Typography>

                    <TextValidator
                        onChange={e => setPackages(e.target.value)}
                        placeholder="com.example"
                        label="Root package"
                        autoComplete="packages"
                        value={packages}
                        validators={['required', 'isValidPackage']}
                        errorMessages={['this field is required', 'package must have least one `.` and at least 5 alphanumeric characters']}
                        fullWidth
                    />
                    <Button type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Continue to Stripe
                    </Button>
                    {back}
                    {messages.map(function (message, index) {
                        return <Alert style={{width:'75%'}} severity="error" key={index}>{message}</Alert>
                    })}
                </Stack>

            </Container>
            </ValidatorForm>
            {/* End hero unit */}
        </React.Fragment>
    );
}

export default Checkout;
