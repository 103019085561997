import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Container} from "@mui/material";

function base64_decode(s) {
    return decodeURIComponent(escape(atob(s)));
}

const Reload = ({setPackages, setTeamSize, setFrequency, setSubscriptionType}) => {

    const { data } = useParams();
    const history = useNavigate();

    React.useEffect(() => {
        const decoded = data ? JSON.parse(base64_decode(data)) : {'subscriptionType' : 'pro', 'packages' : '', 'frequency' : 'month', 'teamSize' : 1};

        setPackages(decoded.packages);
        setTeamSize(decoded.teamSize);
        setFrequency(decoded.frequency);
        setSubscriptionType(decoded.subscriptionType);

        history('/');
    }, [data, history, setPackages, setTeamSize, setFrequency, setSubscriptionType]);



    return (
        <Container/>
    );
}
export default Reload;